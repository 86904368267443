import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { cloneDeep, isEmpty } from 'lodash';
import transform from 'lodash/transform';
import theme from 'theme';
import moment from 'moment-timezone';
import InstructionsHandler from './InstructionsHandler';
import { TASK_RULE_TYPES } from 'types/task';

export const DEFAULT_ERROR_MESSAGE = 'An error occured while performing operation.';

export const RULES = [
  { primaryText: 'Verification', value: 'Verification' },
  { primaryText: 'Feedback', value: 'Feedback' },
  { primaryText: 'Check Flow Rate', value: 'CheckFlowRate' },
  { primaryText: 'Check Quantity', value: 'CheckQuantity' },
  { primaryText: 'Check Temperature', value: 'CheckTemperature' },
  { primaryText: 'Photo Confirmation', value: 'PhotoConfirmation' },
  { primaryText: 'Scan Code', value: 'ScanCode' },
  { primaryText: 'Signature Collection', value: 'Signature' },
  { primaryText: 'Replace', value: 'PartReplacement' },
  { primaryText: 'Report Completion', value: 'ReportCompletion' },
  { primaryText: 'Report Progress', value: 'ReportProgress' },
];

export const addPrefixToObjectKeys = (object = {}, prefix = '') =>
  transform(
    object,
    (result, value, key) => {
      result[`${prefix}.${key}`] = value;
    },
    {},
  );

export const getModuleTheme = module => {
  // Check available properties: https://github.com/mui-org/material-ui/blob/v0.x/src/styles/getMuiTheme.js
  const themes = {
    documents: {
      palette: {
        accent1Color: theme.primaryDocuments,
        primary1Color: theme.primaryDocuments,
      },
    },
    health: {
      palette: {
        accent1Color: theme.primaryHealth,
        primary1Color: theme.primaryHealth,
      },
    },
    home: {
      palette: {
        accent1Color: theme.primary,
      },
    },
    people: {
      palette: {
        accent1Color: theme.primaryPeople,
        primary1Color: theme.primaryPeople,
      },
      checkbox: {
        checkedColor: theme.primaryScheduler,
      },
    },
    queue: {
      palette: {
        accent1Color: theme.primaryQueue,
        primary1Color: theme.primaryQueue,
      },
    },
    sites: {
      palette: {
        accent1Color: theme.primarySites,
        primary1Color: theme.primarySites,
      },
    },
    scheduler: {
      palette: {
        accent1Color: theme.primaryScheduler,
        primary1Color: theme.primaryScheduler,
        primary2Color: theme.primary,
      },
      datePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
      timePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
    },
    tasks: {
      palette: {
        accent1Color: theme.primaryTasks,
        primary1Color: theme.primaryTasks,
      },
    },
    tenants: {
      palette: {
        accent1Color: theme.primaryTenants,
        primary1Color: theme.primaryTenants,
      },
    },
    units: {
      palette: {
        accent1Color: theme.primaryLists,
        primary1Color: theme.primaryLists,
      },
    },
    settings: {
      palette: {
        accent1Color: theme.primaryBlack,
      },
      checkbox: {
        checkedColor: theme.primaryRed,
      },
      radioButton: {
        checkedColor: theme.primaryBlack,
      },
    },
    globalsettings: {
      palette: {
        accent1Color: theme.primaryBlack,
        primary1Color: theme.primaryBlack,
      },
      checkbox: {
        checkedColor: theme.primaryGreen,
      },
    },
    activator: {
      palette: {
        primary1Color: theme.mainRed,
      },
      radioButton: {
        checkedColor: theme.primaryRed,
      },
      datePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
      timePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
    },
    shortcuts: {
      palette: {
        primary1Color: theme.mainRed,
      },
      radioButton: {
        checkedColor: theme.primaryRed,
      },
      datePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
      timePicker: {
        headerColor: theme.headerRed,
        selectColor: theme.mainRed,
      },
    },
  };

  return getMuiTheme(themes[module] || {});
};

export const getModuleThemeForGlobalSettings = module => {
  // Check available properties: https://github.com/mui-org/material-ui/blob/v0.x/src/styles/getMuiTheme.js
  const themes = {
    '': {
      palette: {
        accent1Color: theme.primary,
      },
      checkbox: {
        checkedColor: theme.primaryGreen,
      },
      radioButton: {
        checkedColor: theme.primaryRed,
      },
    },
    systemLinks: {
      checkbox: {
        checkedColor: theme.primaryRed,
      },
    },
    qr: {
      palette: {
        accent1Color: theme.primaryBlack,
        primary1Color: theme.primaryBlack,
      },
    },
  };

  return getMuiTheme(themes[module] || {});
};

export const formatServerError = (error, defaultMessage) =>
  (error &&
    error.response &&
    error.response.data &&
    (error.response.data.message || error.response.data.error || error.response.data.Message)) ||
  defaultMessage;

export const parseServerError = error => {
  const parsedError = { ...error };

  if (error && error.response && error.response.data && typeof error.response.data === 'string') {
    parsedError.message = error.response.data.substring(
      error.response.data.lastIndexOf('<h2> <i>') + 8,
      error.response.data.lastIndexOf('</i> </h2>'),
    );
  } else {
    parsedError.message = formatServerError(error, DEFAULT_ERROR_MESSAGE);
  }

  return parsedError;
};

export const stringComparator = (a, b, ignoreCase) => {
  if (ignoreCase ? a.toUpperCase() > b.toUpperCase() : a > b) {
    return 1;
  } else if (ignoreCase ? b.toUpperCase() > a.toUpperCase() : b > a) {
    return -1;
  }
  return 0;
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export const encodeData = data =>
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'string') {
      data[key] = encodeURIComponent(data[key]);
    }
  });

export const setQrContainerSize = val => {
  if (val === 0) {
    return '0px';
  } else if (val < 15) {
    return '89px';
  } else if (val < 27) {
    return '105px';
  } else if (val < 43) {
    return '121px';
  } else if (val < 63) {
    return '137px';
  } else if (val < 85) {
    return '153px';
  } else if (val < 107) {
    return '169px';
  } else if (val < 123) {
    return '185px';
  } else if (val < 153) {
    return '201px';
  } else if (val < 181) {
    return '217px';
  } else if (val < 214) {
    return '233px';
  } else if (val < 252) {
    return '249px';
  }

  return '265px';
};

export const getTaskName = (name, type) => {
  if (!name) {
    if (type.startsWith('Scan')) {
      return TASK_RULE_TYPES.SCAN_CODE;
    } else if (type.startsWith('Photo')) {
      return TASK_RULE_TYPES.PHOTO_CONFIRMATION;
    } else if (type.endsWith('Quantity')) {
      return TASK_RULE_TYPES.CHECK_QUANTITY;
    } else if (type.endsWith('Temperature')) {
      return TASK_RULE_TYPES.CHECK_TEMPERATURE;
    } else if (type.endsWith('Rate')) {
      return TASK_RULE_TYPES.CHECK_FLOW_RATE;
    } else if (type === TASK_RULE_TYPES.REPLACE) {
      return 'Replace';
    } else if (type === TASK_RULE_TYPES.REPORT_COMPLETION) {
      return 'Report Completion';
    } else if (type === TASK_RULE_TYPES.REPORT_PROGRESS) {
      return 'Report Progress';
    }

    return type;
  }
  return name;
};

export const resizeWindow = () => {
  if (isIE11) {
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  } else {
    window.dispatchEvent(new Event('resize'));
  }
};

export const getTimezoneOffset = timezone => moment().tz(timezone).format('Z');

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const getSearchParam = name => {
  const params = new URLSearchParams(window.location.search);

  return params.get(name);
};

export const searchItemByKeyword = (tableColumns, keyword) => item =>
  tableColumns.some(
    ({ field }) =>
      item[field] && typeof item[field] === 'string' && item[field].toLowerCase().includes(keyword.toLowerCase()),
  );

export const convertDateToThreeDigit = value => moment(value).format('ddd-MMM-DD-YYYY');

export const isDayInThePast = dayForCheck => {
  const today = new Date().setHours(0, 0, 0, 0);
  const dayForCheckMs = new Date(dayForCheck).getTime();

  return today > dayForCheckMs;
};

export const getHourFromDate = value => moment(value).format('hh:mm a');

export const sortObjectByKey = (arrayOfObjects, key, isAsc = true) => {
  if (isEmpty(arrayOfObjects) || !arrayOfObjects) return [];
  if (!key) return arrayOfObjects;

  const clonedArrayOfObjects = cloneDeep(arrayOfObjects);

  return clonedArrayOfObjects.sort((a, b) =>
    a[key] && b[key] ? a[key].localeCompare(b[key]) * (isAsc ? 1 : -1) : !a[key] - !b[key],
  );
};

export const generateStateFromDate = (liveDate, endDate) => {
  let state;
  const LiveDate = moment(liveDate);
  const EndDate = moment(endDate);

  const isCurrentDate = LiveDate.isSameOrBefore(new Date(), 'day') && EndDate.isAfter(new Date(), 'day');
  const isPastDate = EndDate.isBefore();
  const isFutureDate = LiveDate.isAfter();

  if (isCurrentDate || moment().isSame(LiveDate, 'day') || moment().isSame(EndDate, 'day')) {
    state = 'Live';
  } else if (isPastDate) {
    state = 'Old';
  } else if (isFutureDate) {
    state = 'Future';
  }

  return state;
};

export const concatenateDateAndTime = (date, time) => {
  if (date && time) {
    return `${moment(date).format('YYYY-MM-DD[T]')}${moment(time).format('HH:mm')}`;
  }

  return date || time;
};

export const stringifyKeys = values =>
  Object.keys(values).reduce((result, key) => {
    result[`key${key}`] = values[key];
    return result;
  }, {});

export const destringifyKeys = values =>
  Object.keys(values).reduce((result, key) => {
    const hasNumber = /^\d+$/;

    if (hasNumber.test(key)) {
      result[Number(key.substring(3))] = values[key];
    } else {
      result[key.substring(3)] = values[key];
    }

    return result;
  }, {});

export const compareKeys = (a, b) => Object.keys(a).filter(key => !Object.keys(b).includes(key));

export const convertDateToFourDigit = value => moment(value).format('YYYY-MM-DD');

export const convertTimeToDate = value => moment(value, ['h:mm a']).format('HH:mm');

const convertStringToDate = (value, key) => {
  if (key === 'LiveTime' || key === 'EndTime') {
    return convertTimeToDate(value);
  }

  return convertDateToFourDigit(value);
};

export const sortFormattedDate = (array, key, isAsc = true) => {
  if (isEmpty(array) || !array) return [];
  if (!key) return array;

  return array.sort((a, b) => {
    if (!a[key] || !b[key]) return 0;
    a = convertStringToDate(a[key], key);
    b = convertStringToDate(b[key], key);

    return a.localeCompare(b) * (isAsc ? 1 : -1);
  });
};

export const getPageNameByModule = module => {
  let pageName = InstructionsHandler.MODULES[module]?.name;

  switch (module) {
    case 'documents':
      pageName = 'Docs';
      break;
    case 'siteparts':
      pageName = 'Parts';
      break;
    case 'parts':
      pageName = 'Parts M';
      break;
    case 'tenants':
      pageName = 'Tenants';
      break;
    case 'tenantrequests':
      pageName = 'New Tenant Request';
      break;
    case 'upgraderequests':
      pageName = 'Upgrade Requests';
      break;
    case 'units':
      pageName = 'Unit List';
      break;
    default:
      return pageName;
  }

  return pageName;
};

export const RULE_DATA = {
  '': [],
  CheckFlowRate: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'text', isDecimal: true },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'text', isDecimal: true },
  ],
  CheckQuantity: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'text', isDecimal: true },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'text', isDecimal: true },
  ],
  CheckTemperature: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'text', isDecimal: true },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'text', isDecimal: true },
  ],
  Feedback: [],
  PhotoConfirmation: [
    { floatingLabelText: 'Min. Attachments', name: 'RuleData.MinAttachments', type: 'text', isDecimal: true },
    { floatingLabelText: 'Max. Attachments', name: 'RuleData.MaxAttachments', type: 'text', isDecimal: true },
  ],
  ScanCode: [],
  Signature: [],
  Verification: [],
  PartReplacement: [],
  ReportCompletion: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', min: 1, type: 'number', isDecimal: true },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', max: 100, type: 'number', isDecimal: true },
  ],
  ReportProgress: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', min: 1, type: 'number', isDecimal: true },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', max: 100, type: 'number', isDecimal: true },
  ],
};

export const RULE_INITIAL_VALUES = {
  RuleData: {
    ExpectedLowerBound: null,
    ExpectedScanCode: null,
    ExpectedUpperBound: null,
    MaxAttachments: null,
    MinAttachments: null,
  },
  RuleType: '',
};

export const decorator = form => {
  let previousValues = {};

  const unsubscribe = form?.subscribe(
    state => {
      const values = state.values;

      form.batch(() => {
        if (Object.keys(previousValues).length !== 0 && values.RuleType !== previousValues.RuleType) {
          form.change('RuleData.ExpectedLowerBound', null);
          form.change('RuleData.ExpectedScanCode', null);
          form.change('RuleData.ExpectedUpperBound', null);
          form.change('RuleData.MaxAttachments', null);
          form.change('RuleData.MinAttachments', null);
        }

        previousValues = values;
      });
    },
    { touched: true, values: true },
  );

  return unsubscribe;
};

export const getSiteStatistics = (statistics, statisticsLabels) =>
  statistics ? Object.entries(statistics).map(([key, value]) => ({ value, label: statisticsLabels[key] })) : [];

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const findSite = (sitesArray, selectedSite) => {
  const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
  if (site?.additionalId) {
    return `${site.additionalId.substring(0, 5)} ${site.name}`;
  }
  return `${site?.name}`;
};
